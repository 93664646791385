"use strict";
window.CodeJamboree = () => {
    var _a, _b;
    const log = (key, value) => console.log(`${key.padStart(15, "\u00A0")}: ${value}`);
    log("name", process.env.NAME);
    log("version", `v${process.env.VERSION}`);
    log("author", process.env.AUTHOR);
    log("homepage", process.env.HOMEPAGE);
    log("license", process.env.LICENSE);
    log("environment", process.env.NODE_ENV);
    log("timestamp", process.env.BUILD);
    log("build date", new Date(parseInt((_a = process.env.BUILD) !== null && _a !== void 0 ? _a : "0")).toLocaleDateString());
    log("build time", new Date(parseInt((_b = process.env.BUILD) !== null && _b !== void 0 ? _b : "0")).toLocaleTimeString());
    log("repository", process.env.REPOSITORY);
    log("commit hash", process.env.COMMIT);
    log("commit url", `${process.env.REPOSITORY}/commit/${process.env.COMMIT}`);
};
