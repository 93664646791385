import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from "react";
import ErrorDisplay from "./ErrorDisplay";
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { failed: false };
    }
    static getDerivedStateFromError(error) {
        return { failed: true, error };
    }
    componentDidCatch(error, errorInfo) {
        const { onError } = this.props;
        this.setState((prev) => (Object.assign(Object.assign({}, prev), { error: error !== null && error !== void 0 ? error : prev.error, errorInfo })));
        if (typeof onError !== "function")
            return;
        try {
            onError(error, errorInfo);
        }
        catch (e) {
            // do nothing
        }
    }
    render() {
        const { fallback = (_jsx(ErrorDisplay, { error: this.state.error, errorInfo: this.state.errorInfo })), children, } = this.props;
        return this.state.failed ? fallback : children;
    }
}
export default ErrorBoundary;
